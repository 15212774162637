
import styles from "./layout.module.css"

import Row from "./Maincontentcomponents/Row"



import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'font-awesome/css/font-awesome.min.css';
import Square from "./Headercomponents/square"

import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react"

import Logos from "../resources/images/nurse conference logo.png"

import { useNavigate } from "react-router-dom"
import { useRef } from "react"


import Countdown from 'react-countdown';



import registerFade from '../resources/images/laptopImage.png'
import tabletImg from '../resources/images/tabletImg.png'

import conf1 from '../resources/images/conf1.png'
import conf2 from '../resources/images/conf2.png'
import conf3 from '../resources/images/conf3.png'
import conf4 from '../resources/images/conf4.png'
import conf5 from '../resources/images/conf5.png'
import conf6 from '../resources/images/conf6.png'
export default function Layout() {

    const service = useRef(null);
    const address = useRef(null);

    const ourService = () => {

        service.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const ourAddress = () => {

        address.current?.scrollIntoView({ behavior: 'smooth' });
    };


    //header

    const [navbar, setNavbar] = useState(false)
    const modifyHeader = () => {
        // console.log(window.scrollY);
        if (window.scrollY < 80) {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener("scroll", modifyHeader)
    const [display, setDisplay] = useState('none')

    function handleClick() {

        if (display === 'none') {

            setDisplay('block')

        } else {

            setDisplay('none')

        }

    }


    const navigate = useNavigate();


    function Apply() {
        navigate('/new-application');
    }
    const boxVariant = {
        visible: { opacity: 1, y: 0, transition: { duration: 2 } },
        hidden: { opacity: 0.2, y: 20 }
    };
    const control = useAnimation();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);


    const afterFeedback = <div className={styles.afterfeedbackTab} >
        <div>Thanks!!</div>

    </div>

    const feedback = <form className={styles.feedbackTab} onSubmit={handleSubmit}>

        <div className={styles.rating}>
            <div className={styles.rateHeading}>  RATE US</div>

            <div className={styles.rateContent}>
                <div className={styles.rate}>
                    <input type="radio" id="star5" name="rate" value="5" />
                    <label for="star5" title="text">5 stars</label>
                    <input type="radio" id="star4" name="rate" value="4" />
                    <label for="star4" title="text">4 stars</label>
                    <input type="radio" id="star3" name="rate" value="3" />
                    <label for="star3" title="text">3 stars</label>
                    <input type="radio" id="star2" name="rate" value="2" />
                    <label for="star2" title="text">2 stars</label>
                    <input type="radio" id="star1" name="rate" value="1" />
                    <label for="star1" title="text">1 star</label>
                </div>
            </div>
            <div className={styles.rateCaptions}>
                <i className="fa fa-hand-o-up fa-flip "></i>
                Rate Us
            </div>
        </div>

        <div className={styles.comments}>

            <div className={styles.commentTitle}>Kindly leave your feedback</div>
            <div>
                <div className={styles.commentForm}>
                    <textarea required name="comments">

                    </textarea>
                    <button className={styles.applicationButton}>Submit</button>
                </div>
            </div>
        </div>
    </form>

    const [feedbackTab, setfeedbackTab] = useState(feedback);



    async function handleSubmit(event) {
        event.preventDefault();

        const data = new FormData(event.target);

        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: data
        };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}add-comment`, requestOptions);

        const datas = await response.json();

        if (response.ok) {
            setfeedbackTab(afterFeedback)
        }
        else {
            alert('application not sent. please try sending the application again')
        }
    }
    const Completionist = () => <span>Event has Started!</span>;

    // Renderer callback with condition
    const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return <span>{days} days: {hours} hrs : {minutes} mins : {seconds} secs </span>;
        }
    };

    return (
        <div className={styles.layout}>
            <>

                <div className={navbar ? styles.header : styles.headerMinimized}>

                    <div className={styles.headerleft}>
                        <div className={styles.logoFrame}>
                            <img alt="logo" src={Logos} />

                            {/* <div className={styles.logoCaption}> */}
                            {/* <span className={styles.logoCaptionText1}>JOINT</span> */}
                            {/* <span className={styles.logoCaptionText2}>HEALTH CARE SERVICE INC.</span> */}
                            {/* </div> */}
                        </div>
                    </div>
                    <div className={styles.headermiddle}>
                        {/* <Square fa={<i class='fa fa-info' style={{fontSize:20}}></i>} title={"About Us"}/> */}
                        <Square title={"Register"} onClick={ourService} />
                        <Square title={"Nominate"} onClick={ourAddress} />
                        <Square title={"Vote"} onClick={ourAddress} />


                        <a href="tel:233302522909">  <button className={styles.headerButton}><i class='fa fa-phone' style={{ fontSize: 20 }}></i>&nbsp;Make Enqury</button></a>

                    </div>
                    <div className={styles.menuicon}>
                        <button onClick={handleClick}> <i className="fa fa-bars" aria-hidden="true"></i></button>
                        <div className={styles.dropdownmenu} style={{ display: display }}>
                            <div className={styles.dropdown}>
                                {/* <span><i class='fa fa-info' style={{fontSize:20}}></i>&nbsp;About Us</span> */}
                                <span ><a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/register">Register</a></span>
                                <span ><a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com">Login</a></span>
                                <span ><a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/register">Nominate</a></span>
                                <span> <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/register">Vote</a></span>




                            </div>
                        </div>
                    </div>

                </div>
            </>
            {/* <Headers ourService={ourService} ourAddress={ourAddress}/> */}
            <div className={styles.main}>
                <Row>
                    <div className={styles.eventFrame}>
                        <div className={styles.eventName}>
                            <div className={styles.eventTitle}>
                              <span>  5TH ANNUAL NURSING AND MIDWIFERY LEADERS</span><span> AND MANAGERS CONFERENCE AND 4TH</span> <span>EXCELLENCE AWARDS</span>
                            </div>
                            <div className={styles.eventDesc}  >
                                <span className={styles.eventTxtLogo}>
                                    <span className='fa-stack '>
                                        <i className='fa fa-circle fa-stack-2x'></i>
                                        <i className='fa fa-diamond fa-spin fa-stack-1x fa-inverse'></i>
                                    </span>
                                </span>
                                <span className={styles.eventText}>

                                    <span>Empowering Nursing and Midwifery Leaders and Managers</span>
                                    <span> to Promote Quality Healthcare Delivery</span>
                                </span>
                            </div>
                            <div className={styles.eventDesc}  >
                                <span className={styles.eventTxtLogo2}>

                                    <span style={{ fontSize: 40 }} className='fa  fa-clock-o '></span>

                                </span>
                                <span className={styles.eventText2}>

                                    <Countdown date={new Date("October 26, 2024")} renderer={countdownRenderer} />
                                </span>
                            </div>
                        </div>
                        <div className={styles.eventImage}>
                            <Carousel infiniteLoop autoPlay showIndicators={false} showStatus={false} showArrows={false} showThumbs={false} stopOnHover={false} interval={7000} transitionTime={500}>

                                <img className={styles.eventImageFit} src={conf1} />
                                <img className={styles.eventImageFit} src={conf2} />
                                <img className={styles.eventImageFit} src={conf3} />
                                <img className={styles.eventImageFit} src={conf4} />
                                <img className={styles.eventImageFit} src={conf5} />
                                <img className={styles.eventImageFit} src={conf6} />

                            </Carousel>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className={styles.rowContent}>
                        <div className={styles.rowContentLeft}>
                            <div className={styles.why_us_contents}>
                                <div className={styles.ServiceRowContents}>
                                    <div className={styles.services}>
                                        {/* <div className={styles.serviceImageContainer}><img className={styles.serviceImage} src={Img2} alt="services"/></div> */}
                                        <div className={styles.fa_icons}>

                                            <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/register">
                                                <button className={styles.actionButton}>
                                                    {/* <img style={{ height: 50, color: "white" }} src={registerImg} alt="vote" /> */}
                                                    Register
                                                </button>
                                            </a>

                                        </div>
                                        <div className={styles.serviceContent}>
                                            <div className={styles.serviceContentHeader}>

                                            </div>
                                            <div className={styles.serviceContentText}>
                                                <div></div>

                                                <span><strong>*</strong> Fee: GHS 3500</span>
                                            </div>

                                        </div>

                                    </div>


                                    <div className={styles.services}>
                                        <div className={styles.fa_icons}>
                                            <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/nominate">
                                                <button className={styles.actionButton}>
                                                    {/* <img style={{ height: 50, color: "white" }} src={nominateImg} alt="nominate" /> */}
                                                    Nominate
                                                </button>
                                            </a>
                                            {/* <div className={styles.serviceImageContainer}><img className={styles.serviceImage} src={Img2} alt="services"/></div> */}

                                        </div>
                                        <div className={styles.serviceContent}>
                                            <div className={styles.serviceContentHeader}></div>
                                            <div className={styles.serviceContentText}>

                                                <div></div>


                                            </div>

                                        </div>

                                    </div>
                                    <div className={styles.services}>
                                        <div className={styles.fa_icons}>
                                            <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com">
                                                <button className={styles.actionButton}>
                                                    {/* <img style={{ height: 50, color: "white" }} src={loginImg} alt="vote" /> */}

                                                    Login
                                                </button>
                                            </a>
                                            {/* <div className={styles.serviceImageContainer}><img className={styles.serviceImage} src={Img2} alt="services"/></div> */}

                                        </div>
                                        <div className={styles.serviceContent}>
                                            <div className={styles.serviceContentHeader}></div>
                                            <div className={styles.serviceContentText}>

                                                <div></div>


                                            </div>

                                        </div>

                                    </div>
                                    <div className={styles.services}>
                                        {/* <div className={styles.serviceImageContainer}><i className="fa fa-user-md"></i></div> */}

                                        <div className={styles.fa_icons}>
                                            <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/vote">
                                                <button className={styles.actionButton}>
                                                    {/* <img style={{ height: 50, color: "white" }} src={voteImg} alt="vote" /> */}
                                                    Vote
                                                </button>
                                            </a>

                                        </div>
                                        <div className={styles.serviceContent}>
                                            <div className={styles.serviceContentHeader}></div>
                                            <div className={styles.serviceContentText}>
                                                <div></div>

                                                <span><strong>*</strong> USSD app (*713*897#)</span>
                                                {/* <span><strong>*</strong> Web app (www.ww.www)</span> */}
                                                <span><strong>*</strong> Fee: GHS1</span>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </Row>

                <Row>
                    <div className={styles.rowCase}>
                        <div className={styles.rowCaseHeader}>
                            <span>
                                REGISTRATION STEPS
                            </span>
                        </div>
                        <div className={styles.rowCaseContent}>
                            <div className={styles.rowCaseText}>


                                <ol>
                                    <li>Click on the <b>Register</b> button</li>
                                    <li>Fill out the registration form</li>
                                    <li>Log onto the portal using your new credentials </li>
                                    <li>Click on the <b>Make Payment</b> option on the left sidebar</li>
                                    <li>provide your account details and make payment</li>
                                    <li>choose a hotel from the <b>Accommodation menu</b></li>
                                </ol>



                            </div>
                            <div className={styles.rowCaseImg}>

                                <img src={registerFade} />


                            </div>
                        </div>
                    </div>


                </Row>

                <Row>
                    <div className={styles.rowCase}>
                        <div className={styles.rowCaseHeader}>
                            <span>
                                NOMINATION REQUIREMENTS
                            </span>
                        </div>
                        <div className={styles.rowCaseContent}>
                            <div className={styles.rowCaseImg}>

                                <img src={tabletImg} />


                            </div>
                            <div className={styles.rowCaseText}>


                                <ol>
                                    <li>Nominee's Details</li>
                                    <li>Nominee's <b>Passport Picture</b> </li>
                                    <li>Nominee's <b>CV</b></li>
                                    <li>Reasons for nomination</li>
                                    <li>Nominator's details</li>

                                </ol>



                            </div>

                        </div>
                    </div>


                </Row>

                <Row>
                    <div className={styles.rowCase}>
                        <div className={styles.rowCaseHeader}>
                            <span>
                              GENERAL RULES AND CRITERIA FOR NOMINATION
                            </span>
                        </div>
                        <div className={styles.rowCaseContent2}>
                            <div className={styles.rowCaseText2}>
                                <ol>
                                    <li>	Nominations start on 9st September and the deadline for submissions is 9th October, 2024</li>
                                    <li>	The nominee must fit the requirements of the category nominated for</li>
                                    <li>	You cannot nominate yourself</li>
                                    <li>	A nominee cannot be nominated for a category where he/she has previously received an award.</li>
                                    <li>	Previous winners can be nominated into a different category</li>
                                    <li>	Nominees can be in active service or retired.</li>
                                    <li>	You cannot nominate more than one person for one category. Double nominations for the same category will be rejected</li>
                                    <li>	Nominees must be informed before being nominated and indicate their acceptance of the nomination</li>
                                    <li>	Successful nominees will be communicated to and also made known publicly.</li>
                                </ol>
                            </div>
                            <div className={styles.rowCaseText2}>
                                <ol start='10'>
                                    <li>	Supporting documents and evidence should be attached.</li>
                                    <li>	We have introduced a public voting system this year which will attract a small fee of One Ghana Cedis (GHS 1) per single vote using a short code applicable for all networks or through a web-based portal.</li>
                                    <li>	The public nomination and voting will start from 9th September to 15th October 2024.</li>
                                    <li>	Nominations will undergo a review by a panel of experts after the nomination and voting period.</li>
                                    <li>	Ten Highest Shortlisted nominees in line with all requurements will undergo interviews after which the final five except regulation category which is 3 will be selected to receive the award.</li>
                                    <li>	The pass mark for the interview to select the best five per category   will be  60% .</li>
                                    <li>	Winners will be invited to the 5th Leaders and Managers Conference to receive their awards.</li>
                                </ol>
                            </div>
                        </div>
                    </div>


                </Row>

                <Row>
                    <div className={styles.rowCase}>
                        <div className={styles.rowCaseHeader}>
                            <span className={styles.headerStyle1}>
                                <span className={styles.headerStyle2}>CATEGORY 1: NURSING AND MIDWIFERY CLINICAL EXCELLENCE AWARD</span>
                                <small className={styles.headerStyle3}>(ONLY 5 AWARDS AVAILABLE)</small>
                            </span>
                        </div>
                        <div className={styles.rowCaseContent2}>
                            <div className={styles.rowCaseText2}>
                              
                           <i> <b>CRITERIA</b></i>
                                <br />
                               
                                <i> An individual registered NURSE/ MIDWIFE who</i>
                                <ol>

                                    <li>	Provides exemplary direct patient care and contributes to the advancement of nursing practice by demonstrating a commitment to personal leadership development and serving as an inspirational role model for others</li>
                                    <li>	Contributed significantly to the advancement of professional nurses and midwives.</li>
                                    <li>	Improved the quality of direct patient care in his/her facility.</li>
                                    <li>	Recognized by peers for demonstrating professional behaviour in the provision of direct patient care.</li>
                                    <li>	Role model for compassion and exemplary practice.</li>
                                    <li>	Creates an environment that fosters care and compassion.</li>
                                    <li>	Creates an environment where attributes of trust, compassion, mutual respect, continued professional development and ethical behaviour are modelled and supported.</li>

                                </ol>
                            </div>
                            <div className={styles.rowCaseText2}>
                                <ul >
                                    <li>	Is a mentor to staff.</li>
                                    <li>	Accessible, available and responsive to the needs of others, encouraging critical thinking and problem solving for individuals.</li>
                                    <li>	Promotes and enhances the image of nursing and midwifery within the organisation, community and the profession.</li>
                                    <li>	Demonstrates superior clinical nursing knowledge and expert skills for patient care.</li>
                                    <li>	Exhibits excellent customer service skills.</li>
                                    <li>	In good standing with the Nursing and Midwifery Council of Ghana.</li>

                                </ul>
                            </div>
                        </div>
                    </div>


                </Row>
                <Row>
                    <div className={styles.rowCase}>
                        <div className={styles.rowCaseHeader}>
                            <span className={styles.headerStyle1}>
                                <span className={styles.headerStyle2}>CATEGORY 2: NURSING AND MIDWIFERY EDUCATION AND RESEARCH EXCELLENCE AWARD</span>
                                <small className={styles.headerStyle3}>(ONLY 5 AWARDS AVAILABLE)</small>
                            </span>
                        </div>
                        <div className={styles.rowCaseContent2}>
                            <div className={styles.rowCaseText2}>
                          
                            <i> <b>CRITERIA</b></i>
                                <ol>
                                    <li>	The nominee has made or is currently making contributions to the well-being and healthcare of patients through active participation in nursing and midwifery research.</li>
                                    <li>	Should have published at least two (2) papers in a recognized journal.</li>
                                    <li>	Involved in the process if conducting research independently or in collaboration with other disciplines</li>
                                    <li>	Models professionalism via excellence as a mentor, educator and/ or preceptor.</li>

                                </ol>
                            </div>
                            <div className={styles.rowCaseText2}>
                                <ol start="5" >
                                    <li>	Demonstrates excellence through successful mentoring, education and/ or preceptor relationship with others.</li>
                                    <li>	Demonstrates a passion for excellence and exemplifies integrity and fairness.</li>
                                    <li>	Inspires other healthcare professionals by using an optimistic approach for change and encourages the building of relationships that will challenge the learners’ viewpoints.</li>
                                    <li>	Must be in good standing with the Nursing and Midwifery Council of Ghana.</li>

                                </ol>
                            </div>
                        </div>
                    </div>


                </Row>

                <Row>
                    <div className={styles.rowCase}>
                        <div className={styles.rowCaseHeader}>
                            <span className={styles.headerStyle1}>
                                <span className={styles.headerStyle2}>CATEGORY 3: NURSING AND MIDWIFERY REGULATION AND ETHICS</span>
                                <small className={styles.headerStyle3}>(ONLY 3 AWARDS AVAILABLE)</small>
                            </span>
                        </div>
                        <div className={styles.rowCaseContent2}>
                            <div className={styles.rowCaseText2}>
                         
                            <i> <b>CRITERIA</b></i>
                                <br />
                                <i>   The nominee shall be someone who:</i>
                                <ol>
                                    <li>	Promotes public policy related to the safety and effective practice of nursing and midwifery in the interest of public welfare</li>
                                    <li>	Provides education, service and research through collaborative leadership to promote evidence-based regulatory excellence for patient safety and public protection.</li>
                                    <li>	Made an identifiable, significant contribution to the mission and vision of the Nursing and Midwifery Council consistently at all levels.</li>

                                </ol>
                            </div>
                            <div className={styles.rowCaseText2}>
                                <ol start="4" >
                                    <li>	Has authentically demonstrated the promotion of the highest standards of nursing and midwifery ethics at national and international levels.</li>
                                    <li>	Must be in good standing with the Nursing and Midwifery Council of Ghana.</li>

                                </ol>
                            </div>
                        </div>
                    </div>


                </Row>

                <Row>
                    <div className={styles.rowCase}>
                        <div className={styles.rowCaseHeader}>
                            <span className={styles.headerStyle1}>
                                <span className={styles.headerStyle2}>CATEGORY 4: NURSING AND MIDWIFERY LEADERSHIP AND GOVERNANCE</span>
                                <small className={styles.headerStyle3}>(ONLY 5 AWARDS AVAILABLE)</small>
                            </span>
                        </div>
                        <div className={styles.rowCaseContent2}>
                            <div className={styles.rowCaseText2}>
                         
                            <i> <b>CRITERIA</b></i>
                                <br />
                                <i>     The nominee must:</i>
                                <ol>
                                    <li>	Possess extraordinary passion, creativity and dedication to the Nursing and Midwifery profession</li>
                                    <li>	Be respected as a visionary, innovative leader and change agent</li>
                                    <li>	Move the profession forward through work in patient care, administration, education or research via professional activities, endeavours and/ or contributions</li>
                                    <li>	Demonstrate significant contributions fostering the goals of the Professional Nursing and Midwifery Practice Model.</li>
                                    <li>	Have a positive attitude even in challenging situation</li>
                                    <li>	Be a team player</li>
                                    <li>	Act professionally- dedicated, maintains high standards, adaptable and flexible</li>

                                </ol>
                            </div>
                            <div className={styles.rowCaseText2}>
                                <ol start="8" >
                                    <li>	Be a role model – exhibits behaviours that others want to emulate, serves as an example for others and has a positive effect on all.</li>
                                    <li>	Be committed to learning – inquisitive with a thirst for knowledge and critical thinking</li>
                                    <li>	Have contributed to policy development at national and international levels.</li>
                                    <li>	Is currently occupying or has occupied a management position at national, regional, district or agency levels eg. Director, Deputy Director, CNMO, Registrar, Nurse Manager at a facility</li>
                                    <li>	Must be in good standing with the Nursing and Midwifery Council of Ghana</li>

                                </ol>
                            </div>
                        </div>
                    </div>


                </Row>

                <Row>
                    <div className={styles.rowCase}>
                        <div className={styles.rowCaseHeader}>
                            <span className={styles.headerStyle1}>
                                <span className={styles.headerStyle2}>CATEGORY 5: DEPRIVED AREA AWARD: MINISTER FOR HEALTH AWARD</span>
                            
                            </span>
                        </div>
                        <div className={styles.rowCaseContent2}>
                            <div className={styles.rowCaseText2}> 
                                <ul>
                                <li>	Rural or remote location with limited healthcare access</li>
                                            <li>Economically disadvantaged community</li>
                                </ul>
                            </div>
                            <div className={styles.rowCaseText2}>
                                <ul >
                                <li>Inadequate healthcare infrastructure and social amenities.</li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.rowCaseContent2}>
                        
                            <div className={styles.rowCaseText2}>
                            <i><b>Creteria</b></i>
                                <ol>
                           
                                <li>Registered nurses or midwives practicing in Ghana</li>
                                            <li>Minimum 2 years of service in a designated deprived area</li>
                                            <li>Demonstrated impact on community health outcomes</li>
                                            <li>Innovative solutions to resource constraints</li>
                                            <li>Leadership in improving local healthcare delivery</li>
                    
                                </ol>
                            </div>
                            <div className={styles.rowCaseText2}>
                
                                <ol start="6" >
                                                   <li>Community engagement and health education efforts</li>
                                            <li>Detailed description of nominee's work and achievements</li>
                                            <li>Supporting evidence (e.g., statistics, testimonials)</li>
                                            <li>In good standing with the Nursing and Midwifery council of Ghana</li>
                   
                                </ol>
                            </div>
                        </div>
                    </div>


                </Row>

                <Row>
                    <div className={styles.rowCase}>
                        <div className={styles.rowCaseHeader}>
                            <span className={styles.headerStyle1}>
                                <span className={styles.headerStyle2}>AWARD PRIZES</span>
                       
                            </span>
                        </div>
                        <div className={styles.rowCaseContent2}>
                            <div className={styles.rowCaseText2}>
                            
                                <ol>
                                <li> Customized Crystal Glass Plague</li>
                                            <li>Congratulatory Sash</li>
                                            <li>1 day opportunity to participate in the Leaders and Managers Conference</li>
                              
                                </ol>
                            </div>
                            <div className={styles.rowCaseText2}>
                                <ol start="3">
                                <li>1 night Accommodation to participate in the Awards Night with your partner/relative</li>
                                            <li>Any other additional consolation prices that may be introduced</li>
                                  
                                </ol>
                            </div>
                        </div>
                    </div>


                </Row>
              <Row>
                    <div className={styles.rowCase}>
                        <div className={styles.rowCaseHeader}>
                            <span className={styles.headerStyle1}>
                                <span className={styles.headerStyle2}>AWARD PRIZES</span>
                       
                            </span>
                        </div>
                        <div className={styles.rowCaseContent2}>
                            <div className={styles.rowCaseText2}>
                            
                                <ol>
                                <li> Customized Crystal Glass Plague</li>
                                            <li>Congratulatory Sash</li>
                                            <li>1 day opportunity to participate in the Leaders and Managers Conference</li>
                              
                                </ol>
                            </div>
                            <div className={styles.rowCaseText2}>
                                <ol start="3">
                                <li>1 night Accommodation to participate in the Awards Night with your partner/relative</li>
                                            <li>Any other additional consolation prices that may be introduced</li>
                                  
                                </ol>
                            </div>
                        </div>
                    </div>


                </Row>

                <Row>
                    <div className={styles.rowCase}>
                       
                        <div className={styles.rowCaseContent2}>
                        <div className={styles.services}>
                                        <div className={styles.fa_icons}>
                                            <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/nominate">
                                                <button className={styles.actionButton}>
                                                    {/* <img style={{ height: 50, color: "white" }} src={nominateImg} alt="nominate" /> */}
                                                    Nominate
                                                </button>
                                            </a>
                                            {/* <div className={styles.serviceImageContainer}><img className={styles.serviceImage} src={Img2} alt="services"/></div> */}
                                        </div>
                                    </div>

                        </div>
                    </div>


                </Row>
           
            
             



            </div>
            {/* <Footer/> */}

            <div ref={address} className={styles.footer} >
                <div className={styles.footerUpper}>
                    <div className={styles.logoFrame}>
                        <img alt="logo" src={Logos} />
                        {/* <img alt="logo" src={Logo} style={{width:140, height:140}}/> */}
                        {/* <div className={styles.logoCaption}> */}
                        {/* <span className={styles.logoCaptionText1}>JOINT</span> */}
                        {/* <span className={styles.logoCaptionText2} style={{color:'white'}}>HEALTH CARE SERVICE INC.</span> */}
                        {/* </div> */}
                    </div>
                    <div class={styles.socialmedia}>
                        <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
                        <a href="#" class="twitter"><i class="fa fa-twitter"></i></a>
                        <a href="#" class="google"><i class="fa fa-instagram"></i></a>
                        <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>

                    </div>
                </div>

                <div className={styles.footerdescription}>
                    5TH ANNUAL NURSING AND MIDWIFERY LEADERS AND MANAGERS CONFERENCE AND 4TH EXCELLENCE AWARDS
                </div>

                <div className={styles.footerSub}>
                    <span>Copyright 2024 <span style={{ color: "greenyellow" }}>
                        NMC CONFERENCE

                    </span> All Rights Reserved  </span>
                    <span><a>Privacy Policy</a></span>
                </div>

            </div>
        </div>
    )

}